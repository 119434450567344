import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { AiOutlineMail } from "react-icons/ai"
import { GrChatOption } from "react-icons/gr"
import { FaProjectDiagram } from "react-icons/fa"
import { Button } from "react-bootstrap"

const CommunityPage = () => {
  const [mailOpen, setMailOpen] = useState(false)
  return (
    <Layout>
      <SEO title="Community - PCS" description="" lang="en" meta={[]} />

      {/* Hero */}
      <div className="banner-image pcs join">
        <div className="hero-text">
          <h1>PCS Community</h1>
          <h3 className="text-center">
            We are excited for your interest in the PCS community!
          </h3>
          <h3>
            There are 3 ways to engage with the community depending on your
            interest level: passive, active, proactive.
          </h3>
        </div>

        {/* Platforms Section */}
        <section className="platform-section">
          <div className="container">
            <div className="row d-flex align-items-start">
              <div className="col-lg comm-block">
                <div className="header-div">
                  <div className="engagement ">
                    <span className="one">Passive</span>
                  </div>
                  <AiOutlineMail />
                  <h3>Mailing List</h3>
                </div>
                <div className="text-div">
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Fugit quas non sapiente, voluptatum quo aliquid dolor,
                    dolorem quia aut eveniet odio beatae soluta maiores at cum
                    blanditiis enim. Rem provident veritatis culpa quas porro.
                  </p>
                  {mailOpen ? (
                    <div>
                      <div className="mail-signup">
                        <input type="text" placeholder="Email Address" />
                        <Button>Submit</Button>
                      </div>
                      <Button
                        variant="secondary"
                        onClick={() => setMailOpen(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button variant="primary" onClick={() => setMailOpen(true)}>
                      Join
                    </Button>
                  )}
                </div>
              </div>

              <div className="col-lg comm-block">
                <div className="header-div">
                  <div className="engagement ">
                    <span className="two">Active</span>
                  </div>
                  <GrChatOption />
                  <h3>Forum</h3>
                </div>
                <div className="text-div">
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Fugit quas non sapiente, voluptatum quo aliquid dolor,
                    dolorem quia aut eveniet odio beatae soluta maiores at cum
                    blanditiis enim. Rem provident veritatis culpa quas porro.
                  </p>
                  <Button
                    variant="primary"
                    as="a"
                    href="https://community.flapmax.com/c/developer/pcs/18"
                    target="_blank"
                  >
                    Discuss
                  </Button>
                </div>
              </div>
              <div className="col-lg comm-block">
                <div className="header-div">
                  <div className="engagement ">
                    <span className="three">Proactive</span>
                  </div>
                  <FaProjectDiagram />
                  <h3>Contributing</h3>
                </div>
                <div className="text-div">
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Fugit quas non sapiente, voluptatum quo aliquid dolor,
                    dolorem quia aut eveniet odio beatae soluta maiores at cum
                    blanditiis enim. Rem provident veritatis culpa quas porro.
                  </p>
                  <Button variant="primary">Contribute</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CommunityPage
